<template>
  <div class = 'notfoundpage'>
    <img v-if = '$store.state.width > 765' src="../../static/imgs/404.png" alt="">
    <div class="content">
      <div class="bigTitle">404</div>
      <div class="smallTitle">UH OH!页面丢失</div>
      <div class="descript">你所寻找的页面不存在,你可以点击下面的按钮,返回主页</div>
      <mu-button round class = 'gohome' @click = 'goHome' color="primary">返回主页</mu-button>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        
      }
    },
    methods:{
      goHome(){
          this.$router.push('/index')
      }
    },
  }
</script>

<style lang="less" scoped>
  .notfoundpage{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:calc(100vh - 165px);
    img{
      width:400px
    }
    .content{
      .bigTitle{
        font-size: 80px;
        font-weight: 900;
        line-height: 1;
      }
      .smallTitle{
        font-size: 30px;
        font-weight:900
      }
      .descript{
        font-size: 16px;
        font-weight: bold;
      }
      .gohome{
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }
</style>
